<template>
  <div class="viewContainer">
    <v-container
      class="pa-4 pa-sm-8"
    >
      <v-card class="content-tabs-wrapper px-4 px-sm-8 py-4 py-sm-8" elevation="0">
        <div class="content-container">
          <v-tabs 
            v-model="tab"
            :color="($store.state.app.dark) ? 'white' : 'black'"
            class="content-tabs"
            background-color="transparent"
            fixed-tabs
            show-arrows
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab @click="changeTab" class="no-caps" :href="'#general'" :ripple="false">
              {{ $t('general') }}
            </v-tab>
            <!-- <v-tab class="no-caps" :href="'#localization'" :ripple="false">
              {{ $t('localization') }}
            </v-tab> -->
            <v-tab @click="changeTab" v-if="$auth.user().status_google != 1" class="no-caps" :href="'#password'" :ripple="false">
              {{ $t('password') }}
            </v-tab>
          </v-tabs>

          <div class="mt-8">
            <ValidationObserver ref="form1" v-slot="{ invalid }">
              <v-form 
                :model="form1" 
                id="form1"
                lazy-validation
                @submit.prevent="submitForm('form1')"
                autocomplete="off"
                method="post"
                accept-charset="UTF-8" 
                enctype="multipart/form-data"
              >
                <v-alert
                  :value="form1.has_error && !form1.success"
                  text
                  type="error"
                  class="mb-4"
                >
                  <span v-if="form1.error === 'registration_validation_error'">{{ $t('server_error') }}</span>
                  <span v-else-if="form1.error === 'demo'">This is a demo user. You can't update or delete anything this account. If you want to test all user features, sign up with a new account.</span>
                  <span v-else>{{ $t('correct_errors') }}</span>
                </v-alert>
                <v-alert
                  :value="form1.success"
                  text
                  type="success"
                  class="mb-4"
                >
                  {{ $t('update_success') }}
                </v-alert>
                
                <v-tabs-items v-model="tab" :touchless="false">
                  <v-tab-item :transition="false" :reverse-transition="false" :value="'general'">
                    <v-card flat tile>
                      <div class="mb-4">
                        <v-label :class="'input-label mb-2 ' +label_cover">{{ $t('avatar') }}</v-label>
                        <v-hover>
                          <template v-slot:default="{ hover }">
                            <v-avatar size="150" class="input-upload avatar">
                              <v-img :src="form1.avatar_media_url" contain 
                              :style="{'width': '100%'}" >
                                <v-fade-transition>
                                  <v-overlay
                                    v-if="hover"
                                    absolute
                                  >
                                    <!-- <v-btn-toggle rounded> -->
                                      <v-btn @click="pickFile('avatar')" small fab color="primary" rounded><v-icon size="14">mdi-upload</v-icon></v-btn>
                                      <v-btn v-if="form1.avatar_media_file && form1.avatar_media_file_editor" @click="EditImage" class="ml-2" small fab color="warning"><v-icon size="14">mdi-pencil</v-icon></v-btn>
                                      <v-btn v-if="showDeleteAvatar" @click="$refs.fileupload.value=null;form1.avatar_media_file = null; form1.avatar_media_url = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA9JREFUeNpiuHbtGkCAAQAFCAKDZcGh3gAAAABJRU5ErkJggg=='; form1.avatar_media_changed = true; showDeleteAvatar = false" class="ml-2" small fab color="error"><v-icon size="14">mdi-close</v-icon></v-btn>
                                    <!-- </v-btn-toggle> -->
                                  </v-overlay>
                                </v-fade-transition>
                              </v-img>
                            </v-avatar>
                          </template>
                        </v-hover>
                        <input
                          type="file"
                          style="display: none"
                          id="avatar"
                          name="avatar"
                          ref="fileupload"
                          accept="image/*"
                          @change="onFilePicked"
                        >
                      </div>

                      <v-row class="my-0">
                        <v-col class="py-0" cols="12" sm="6">
                          <x-text-field 
                            v-model="form1.first_name"
                            ref="form1.first_name"
                            id="form1.first_name"
                            :label="$t('first_name')"
                            :name="$t('first_name')"
                            rules="required|min:1|max:32"
                          />
                        </v-col>
                        <v-col class="py-0" cols="12" sm="6">
                          <x-text-field 
                            v-model="form1.last_name"
                            ref="form1.last_name"
                            id="form1.last_name"
                            :label="$t('last_name')"
                            :name="$t('last_name')"
                            rules="required|min:1|max:32"
                          />
                        </v-col>
                      </v-row>

                      <v-row no-gutters>
                        <v-col cols="12" class="">
                          <x-text-field 
                            type="email"
                            v-model="form1.email"
                            ref="form1.email"
                            id="form1.email"
                            :label="$t('email')"
                            rules="required|max:64|email"
                          />
                        </v-col>

                        <v-col cols="12" class="">
                          <x-select
                            :items="item_gender"
                            v-model="form1.gender"
                            :label="$t('gender')"
                            item-text="name"
                            item-value="value"
                            filled
                          ></x-select>
                        </v-col>

                        <v-col cols="12" class="">
                          <x-date-picker
                            v-model="form1.date_of_birth"
                            ref="date_of_birth"
                            id="date_of_birth"
                            :label="$t('birthday')"
                            name="date_of_birth"
                            :max="moment().format('YYYY-MM-DD')"
                            prepend-inner-icon="mdi-timer"
                            locale="th"
                            _format="form_item.format"
                            :persistent-hint="true"
                            filled
                          />
                        </v-col>

                      </v-row>

                    </v-card>
                  </v-tab-item>
                  <!-- <v-tab-item :transition="false" :reverse-transition="false" :value="'localization'">
                    <v-card flat tile>
                      <x-autocomplete
                        v-model="form1.locale"
                        :items="locales"
                        item-value="0" 
                        item-text="1"
                        :label="$t('locale')"
                        :name="$t('locale')"
                        rules="required"
                        :hint="$t('locale_hint')"
                        :persistent-hint="true"
                      />
                      <x-autocomplete
                        v-model="form1.timezone"
                        :items="timezones"
                        item-value="0" 
                        item-text="1"
                        :label="$t('timezone')"
                        :name="$t('timezone')"
                        rules="required"
                      />
                    </v-card>
                  </v-tab-item> -->
                  <v-tab-item v-if="$auth.user().status_google != 1" :transition="false" :reverse-transition="false" :value="'password'">
                    <v-card flat tile>
                      <x-password
                        v-model="form1.new_password"
                        ref="form1.new_password"
                        id="form1.new_password"
                        :label="$t('change_password')"
                        :name="$t('password')"
                        rules="min:8|max:24"
                        :hint="$t('leave_empty_for_no_change')"
                        :persistent-hint="true"
                      />
                      <x-password
                        v-model="form1.current_password"
                        ref="form1.current_password"
                        id="form1.current_password"
                        :label="$t('current_password')"
                        :name="$t('current_password')"
                        rules="min:8|max:24"
                        :hint="$t('current_password_info')"
                        persistent-hint
                      />
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
                  
                <v-card-actions class="pa-0 mt-4">
                  <v-btn color="primary" block rounded depressed x-large :loading="form1.loading" :disabled="form1.loading || invalid" type="submit" class="no-caps mb-2" style="min-width: 200px">{{ $t('update') }} <v-icon right>mdi-arrow-right</v-icon></v-btn>
                </v-card-actions>
              </v-form>
            </ValidationObserver>
          </div>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
// import MenuBusinessSetting from '@/components/ui/MenuBusinessSetting.vue'

export default {
    components: {
      //MenuBusinessSetting
    },
    data() {
      return {
        tab: 'general',
        activeFilePickerId: null,
        showDeleteAvatar: false,
        showDeleteCover: false,
        locales: [],
        timezones: [],
        formDirty: false,
        tabImg: 'about:blank',
        tabImgHeight: null,
        tabImgDefaultHeight: 780,
        tabImgAspectRation: 1.7778,
        tabImgs: [],
        label_cover: '',
        item_gender: [
          {
            value: 1,
            name: this.$t('male')
          },
          {
            value: 2,
            name: this.$t('female')
          },
          {
            value: 3,
            name: this.$t('no_gender')
          },
        ],
        form1: {
          loading: false,
          first_name: this.$auth.user().first_name,
          last_name: this.$auth.user().last_name,
          email: this.$auth.user().email,
          avatar_media_url: this.$auth.user().avatar,
          avatar_media_changed: false,
          locale: this.$auth.user().locale,
          timezone: this.$auth.user().timezone,
          new_password: null,
          current_password: null,
          has_error: false,
          error: null,
          gender: this.$auth.user().gender ? this.$auth.user().gender : 3,
          date_of_birth: (this.$auth.user().date_of_birth ? this.moment(this.$auth.user().date_of_birth).format('YYYY-MM-DD')  : null),
          success: false,
          avatar_media_file: null
        },
      }
    },
    mounted () {
      this.axios
        .get('/localization/locales', { params: { locale: this.$i18n.locale }})
        .then(response => {
          this.locales = this.$_.toPairs(response.data)
        })
      this.axios
        .get('/localization/timezones', { params: { locale: this.$i18n.locale }})
        .then(response => {
          this.timezones = this.$_.toPairs(response.data)
        })
    },
    created () {
      this.showDeleteAvatar = (this.$_.startsWith(this.form1.avatar_media_url, 'data:image/png;base64')) ? false : true
      
      // Tab images
      this.tabImgs = [
        this.$init.config.schemeAndHost + '/img/business-rules-tab.jpg'
      ]

      // Set image depending on the size of the browser window
      if (window.innerWidth > 960) {
        this.tabImg = this.tabImgs[0]
        this.tabImgHeight = this.tabImgDefaultHeight
        this.tabImgAspectRation = 1.7778
      } else {
        this.tabImgHeight = null
        this.tabImgAspectRation = 3
      }
      // Preload
      let images = new Array()
      this.tabImgs.forEach(function(img, i) {
        images[i] = new Image()
        images[i].src = img
      })

    },
    watch: {
      form1: {
        handler(newVal, oldVal) {
          if (!this.formDirty) {
            // Validate current password
            //this.$refs['form1.current_password'].validate()
            this.formDirty = true
          }
        },
        deep: true
      }
    },
    methods: {
      async submitForm(formName) {
        // Reset form validation
        this.$refs[formName].reset()

        this[formName].success = false
        this[formName].has_error = false
        this[formName].loading = true

        this.updateProfile(formName)
      },
      changeTab(){
        this.form1.success = false
        this.form1.has_error = false
      },
      updateProfile(formName) {

        
        if(this.form1.avatar_media_changed &&!this.form1.avatar_media_file){
          this.label_cover = 'error--text'
          this.$root.$snackbar(this.$t('enter_img'))
          return false
        }

        var app = this[formName]

        let settings = { headers: { 'content-type': 'multipart/form-data' } }

        // Remove image urls
        let formModel = Object.assign({}, this.form1)
        formModel.avatar_media_url = null
        formModel.language = this.$i18n.locale

        let formData = new FormData(document.getElementById('form1'))

        for (let field in formModel) {
          let val = (formModel[field] === null) ? '' : formModel[field]
          formData.append(field, val)
        }
        if(this.form1.avatar_media_file_editor){
          formData.append('avatar_media_file', this.form1.avatar_media_file_editor) 
        }

        this.axios
          .post('/auth/profile', formData, settings)
          .then(response => {
            if (response.data.status === 'success') {
              app.success = true
              app.new_password = null
              app.current_password = null
              this.$nextTick(() => this.$refs[formName].reset())

              // Update auth object
              this.$auth.user(response.data.user)
            }
          })
          .catch(error => {
            app.has_error = true
            if (error.response.data.status === 'error') {
              if (typeof error.response.data.error !== 'undefined') app.error = error.response.data.error
              this.errorMsg = error.response.data.error

              let errors = error.response.data.errors || []

              for (let field in errors) {
                this.$refs[formName + '.' + field].applyResult({
                  errors: errors[field],
                  valid: false,
                  failedRules: {}
                })
              }
            }
          })
          .finally(() => { 
            window.scrollTo(0,0)
            app.loading = false
          })
      },
      pickFile (id) {
        this.activeFilePickerId = id
        document.getElementById(id).click();
      },
      onFilePicked (e) {
        const files = e.target.files
        if(files[0] !== undefined) {
          if(files[0].name.lastIndexOf('.') <= 0) {
            return
          }
          const fr = new FileReader ()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {
            this.form1[this.activeFilePickerId + '_media_url'] = fr.result
            this.form1[this.activeFilePickerId + '_media_file'] = files[0]
            this.form1[this.activeFilePickerId + '_media_file_editor'] = files[0]
            this.form1[this.activeFilePickerId + '_media_changed'] = true
            this.$root.$ImageEditor(files[0],null)
            .then((confirm) => {
              if (confirm.dest) {
                this.form1[this.activeFilePickerId + '_media_url'] = URL.createObjectURL(confirm.dest);
                this.form1[this.activeFilePickerId + '_media_file_editor'] = confirm.dest
              }
            })
            switch (this.activeFilePickerId) {
              case 'avatar':
                this.showDeleteAvatar = true
                break;
            }
          })
        } else {
          this.form1[this.activeFilePickerId + '_media_file'] = ''
          this.form1[this.activeFilePickerId + '_media_file_editor'] = ''
          this.form1[this.activeFilePickerId + '_media_url'] = ''
          this.form1[this.activeFilePickerId + '_media_changed'] = true
        }
      },
      EditImage(){
        this.$root.$ImageEditor(this.form1[this.activeFilePickerId + '_media_file'],null)
        .then((confirm) => {
          if (confirm.dest) {
            this.form1[this.activeFilePickerId + '_media_url'] = URL.createObjectURL(confirm.dest);
            this.form1[this.activeFilePickerId + '_media_file_editor'] = confirm.dest
          }
        })
      },
    },
    computed: {
      app () {
        return this.$store.getters.app
      }
    }
  }
</script>
<style>
  .margin-top-0{
    margin-top: 0px;
  }
</style>